
var ajaxLoadRow = ( function ( $, ajaxLoadRow ) {
    'use strict';

    var $content, $contentRows, $messages;
    var editors = {};
    var sliders = {};

    ajaxLoadRow.url  = null;
    ajaxLoadRow.limit  = null;

    ajaxLoadRow.renderError = function(message) {
        var $error = $('<div/>').addClass('alert alert-danger').text(message);
        $messages.empty($messages);
        $messages.append($error);
    };

    ajaxLoadRow.initializeCKEditor = function() {
        const targets = document.querySelectorAll("[data-ckeditor-name]:not(.observed)");

        const lazyLoad = (target)=>{
            const io = new IntersectionObserver((entries,observer)=>{
                entries.forEach(entry=>{
                    if(entry.isIntersecting){
                        var el = entry.target;
                        $(el).addClass('observed');
                        var name = $(el).data('ckeditor-name-uniq');
                        //console.log('observed', name);
                        if (typeof editors[name] === 'undefined') {
                            BalloonEditor.create(el, {

                            })
                            .then(editor => {
                                editors[name] = editor;
                                $(el).data('editor', editor);
                            })
                            .catch(error => {
                                console.error('There was a problem initializing the editor.', error);
                            });
                        } else {
                            $(el).data('editor', editors[name]);
                        }
                        observer.disconnect();
                    }
                })
            },{threshold:[0]});

            io.observe(target);
        }
        targets.forEach(lazyLoad);
    };

    // galerie produktu
    ajaxLoadRow.initializeSlider = function(){
        $(".product-thumbnail").each(function(){
            var name = $(this).find('.product-thumbnail-carousel').attr('id');
            if (typeof sliders[name] === 'undefined') {
                var self = $(this);

                var $slider = $(this).find('.product-thumbnail-items').lightSlider({
                    item: 1,
                    controls: false,
                    pager: false,
                    loop: true,
                    adaptiveHeight: true,
                    onBeforeSlide: function(el) {
                        self.find('.product-thumbnail-caption-indicator').text(el.getCurrentSlideCount());
                        var $img = el.find('.lslide').eq(el.getCurrentSlideCount()-1).find('img');
                        $img.attr('src', $img.attr('data-src'));
                    },
                    onSliderLoad: function() {
                        self.find('.product-thumbnail-carousel').removeClass('is-loading');
                    }
                });

                $(this).delegate('.carousel-control-next', 'click', function(e){
                    e.preventDefault();
                    $slider.goToNextSlide();
                });

                $(this).delegate('.carousel-control-prev', 'click', function(e){
                    e.preventDefault();
                    $slider.goToPrevSlide();
                });

                sliders[name] = $slider;

                var lightbox = $(this).find('.product-thumbnail-item').not('.clone').simpleLightbox({
                    sourceAttr: 'data-img',
                    alertError: false,
                });

                var isDragging = false;
                $(this).find('.product-thumbnail-item').not('.clone').mousedown(function() {
                    isDragging = false;
                }).mousemove(function() {
                    isDragging = true;
                }).mouseup(function(e) {
                    var wasDragging = isDragging;
                    isDragging = false;

                    if (!wasDragging && e.button !== 2) {
                        lightbox.open($(this));
                    }
                });
            }
        });

        activeLazyload();
    };

    ajaxLoadRow.renderRow = function(limit, loader = true) {
        var ids = [];
        var keys = [];
        $contentRows.find('[data-ajaxloadrow-id]').each(function(i, el) {
            ids.push($(el).data('ajaxloadrow-id'));
            keys.push($(el).data('ajaxloadrow-key'));
        });

        if (loader) {
            $('body').addClass('is-loading');
        }

        $.ajax({
            url: ajaxLoadRow.url,
            data: {
                limit: limit,
                renderIds: ids,
                renderKeys: keys
            },
            success: function(html) {
                if (html) {
                    $contentRows.append(html);
                    window.tooltipStart();
                    ajaxLoadRow.initializeCKEditor();
                    ajaxLoadRow.initializeSlider();
                }
                else if (ids.length === 0) {
                    $('[data-ajaxloadrow-no-item]').show();
                }
                $('body').removeClass('is-loading');
                window.refreshMenuCount();
            }
        });
    };

    ajaxLoadRow.copyEditorToTextArea = function() {
        $.each(editors, function(key, editor) {
            var $editor = $('[data-ckeditor-name-uniq="' + key + '"]');
            if ($editor.length) {
                var $textarea = $('textarea[name="' + $editor.data('ckeditor-name') + '"]');
                var text = editor.getData();
                $textarea.val(text);
            }
        });
    };

    ajaxLoadRow.clearEmptyRow = function() {
        var $rows = $('[data-ajaxloadrow-row]');
        $rows.each(function(i, el) {
            var $row = $(el);
            var $textarea = $row.find('[data-textarea-part-text]');
            var $text = $row.find('[data-text-part-id].active');
            if ($textarea.length === 0 && $text.length === 0) {
                $row.remove();
            }

        });
        //console.log(ajaxLoadRow.limit - $('[data-ajaxloadrow-row]').length);
        ajaxLoadRow.renderRow(ajaxLoadRow.limit - $rows.length, false);
    };

    ajaxLoadRow.actionRow = function($this) {
        // musíme zjistit dříve než nastavíme btn jako disabled jinak hodnotu nedostaneme
        var action = $this.find('button:focus').data('ajaxloadrow-save');
        $this.find('button').prop('disabled', true);

        var $row = $this.closest('[data-ajaxloadrow-row]');
        var url = $row.find('form').data('ajaxloadrow-action-url');

        $.post({
            url: url,
            data: $this.serialize() + '&action=' + action,
            success: function(data) {
                if (data.status === 'success') {
                    $row.fadeOut("slow", function() {
                        $row.remove();

                        // pokud v seznamu jsou stejné fráze, tak je odstraníme, protože předpokládáme, že se přeložili
                        $row.find('[data-textarea-part-text]').each(function(i, el) {
                            var id = $(el).data('textarea-part-text');
                            $content.find('[data-text-part-id="' + id + '"]')
                                .removeClass('active')
                                .addClass('disabled')
                                .removeAttr('data-text-part-id');
                            $content.find('[data-textarea-part-text="' + id + '"]').closest('.phrase-edit-textarea').remove();
                        });
                        ajaxLoadRow.clearEmptyRow();
                    });
                }
                else {
                    ajaxLoadRow.renderError(data.message);
                }
            },
            error: function() {
                ajaxLoadRow.renderError('Response error');

                $this.find('button').prop('disabled', false);
            }
        });
    };

    ajaxLoadRow.initialize = function(renderUrl, limit, extendLockUrl)
    {
        ajaxLoadRow.url = renderUrl;
        ajaxLoadRow.limit = limit;

        $content = $('[data-ajaxloadrow-content]');
        $contentRows = $content.find('[data-ajaxloadrow-rows-content]');
        $messages = $content.find('[data-ajaxloadrow-messages]');

        ajaxLoadRow.renderRow(ajaxLoadRow.limit);

        $content.find('[data-ajaxloadrow-next]').on('click', function(ev) {
            ev.preventDefault();
            ajaxLoadRow.renderRow(10, false);
        });

        $content.delegate('form', 'submit', function(ev) {
            ev.preventDefault();
            var $form = $(this);

            var copyReady = true;
            $form.find('[data-ckeditor-name-uniq]').each(function(i, el) {
                var $editor = $(el);
                var editor = $editor.data('editor');
                var $textarea = $('textarea[name="' + $editor.data('ckeditor-name') + '"]');
                if ($textarea.length === 0 || !editor) {
                    copyReady = false;
                    return false;
                }
                $textarea.val(editor.getData());
            });

            if (copyReady === false) {
                ajaxLoadRow.renderError('Failed to copy text before submitting form.');
            } else {
                ajaxLoadRow.actionRow($form);
            }
        });

        // pokud mám focus v textaree tak zaktivníme část textu
        $content.delegate('[data-ckeditor-name]', 'focus', function() {
            var name = $(this).data('ckeditor-name');
            var $text = $(this).closest('form').find('[data-text-part-id="' + name + '"]');
            var $allTexts = $('[data-text-part-id]');

            $('.phrase-edit-textarea').removeClass('active');
            $(this).closest('.phrase-edit-textarea').addClass('active');

            $allTexts.removeClass('active');
            $text.addClass('active');

            $(this).closest('.phrase-edit-textarea').addClass('selected');
        });

        $content.delegate('[data-ckeditor-name]', 'focusout', function() {
            $('[data-text-part-id]').removeClass('active');
            $('.phrase-edit-textarea').removeClass('active');
        });

        $content.delegate('[data-text-part-id]', 'click', function() {
            var sel = getSelection().toString();
            if (sel) {
                return false;
            }

            var name = $(this).data('text-part-id');
            var $input = $(this).closest('form').find('[data-ckeditor-name="' + name + '"]');
            if ($(this).is('.ckeditor')) {
                // to translate skip
                $input.closest('.phrase-edit-textarea').addClass('active');
            }
            else {
                // to translate
                $input.focus().select();
            }
        });

        // hover zvyrazneni
        $content.delegate('.phrase-edit-textarea', 'mouseover', function() {
            var name = $(this).find('[data-ckeditor-name]').data('ckeditor-name');
            var $text = $(this).closest('form').find('[data-text-part-id="' + name + '"]');
            var $allTexts = $('[data-text-part-id]');

            $('.phrase-edit-textarea').removeClass('hover');
            $(this).addClass('hover');

            $allTexts.removeClass('hover');
            $text.addClass('hover');
        });

        $content.delegate('.phrase-edit-textarea', 'mouseout', function() {
            $('[data-text-part-id]').removeClass('hover');
            $(this).removeClass('hover');
        });

        $content.delegate('[data-text-part-id]', 'mouseover', function() {
            var name = $(this).data('text-part-id');
            var $input = $(this).closest('form').find('[data-ckeditor-name="' + name + '"]');
            $input.closest('.phrase-edit-textarea').addClass('hover');
            $(this).addClass('hover');
        });

        $content.delegate('[data-text-part-id]', 'mouseout', function() {
            $('.phrase-edit-textarea.hover').removeClass('hover');
            $(this).removeClass('hover');
        });

        // co 5 minut budeme prodlužovat zámky
        if (typeof extendLockUrl !== 'undefined') {
            setInterval(function() {
                var ids = [];
                $contentRows.find('[data-ajaxloadrow-id]').each(function(i, el) {
                    ids.push($(el).data('ajaxloadrow-id'));
                });
                $.ajax({
                    url: extendLockUrl,
                    data: { ids: ids },
                });
            }, ((1000 * 60) * 5));
        }
    };

    return ajaxLoadRow;
}( window.jQuery, ajaxLoadRow || { } ) );

window.ajaxLoadRow = ajaxLoadRow;
